import React, { useContext, useEffect } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "@emotion/styled"
import {
  color,
  breakpoints,
  NavUlStyles,
  NavStylesNew,
  SubmenuStylesNew,
} from "../../utils/style"
import Logo from "../Header/LogoSvg"
import Hamburger from "./Icons/Hamburger"
import MobileContactLinkFresh from "./Icons/MobileContactLinkFresh"
import MobileLineLink from "./Icons/MobileLineLink"
import MobileCallLink from "./Icons/MobileCallLink"
import IcomoonIcon from "../shared/IcomoonIcon"
import HeaderContacts from "../Header/HeaderContacts"
import { isMobile } from "react-device-detect"
import { LogoutButton } from "../Auth/Auth"
import StoreContext from "../shared/Context/StoreContext"
import MobileCloseLink from "../Header/MobileCloseLink"
import SearchForm from "../Search/Form"
import Gift from "../../icons/svg/Gift"
import TopSearch from "./Icons/TopSearch"

const NavWrap = styled("div")`
  position: relative;
  z-index: 1;
  .logo {
    display: inline-block;
    margin: 0 2% 0 0;
    width: 255px;
    max-width: 90%;
    padding: 7px 0;
    position: relative;
    z-index: 1;
    svg {
      max-width: 100%;
    }
  }
  .active {
    > a {
      color: #ffcdcd;
      text-shadow: 0 0 5px #d60000, 0 1px 4px #e02020;
      svg {
        path {
          fill: ${color.peach} !important;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 5px;
    position: static;
    .logo {
      padding: 10px;
      max-width: none;
      margin: 0;
    }
    &.search-open {
      position: relative;
      z-index: 100;
      width: 100%;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    position: static;
    .logo {
      position: absolute;
      left: 23.5%;
      top: 8px;
      padding: 0;
      margin: 0;
      max-width: 54%;
      margin-top: 5px;
    }
  }
  @media (max-width: ${breakpoints.md}) and (orientation: landscape) {
    &.invisible-header {
      display: none;
    }
  }
`

const DesktopWrapper = styled("div")`

  max-width: 1244px;
  margin: 0 auto;
  padding: 0 0 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .desktop-limiter {
    flex-basis: 45.5%;
    .header-promo-link {
      float: right;
      margin: 7px -15px 0 0;
      background: ${color.transparentWhite1};
      border: none;
      padding: 14px 23px;

      span {
        display: inline-block;
        vertical-align: top;
        letter-spacing: 1px;
        line-height: 20px;
        margin-left: 10px;
      }
      svg {
        display: inline-block;
        vertical-align: bottom;
      }
      &:hover,
      &:active,
      &:focus,
      &.active {
        background: ${color.transparentWhite2};
        span {
          text-shadow: 0 0 9px ${color.brightRed};
          color: ${color.lightPeach};
        }
      }
    }
    .line-id-mobile {
      display: none;
    }
  }

  }
  @media (min-width: ${breakpoints.smMin}) {
    .header-promo-link {
      width: 171px;
      height: 48px;
      > svg {
        margin-top: -5px;
      }
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    .desktop-limiter {
      flex-basis: 36%;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    position: sticky;
    padding-top: 0;
    top: 70px;
    left: 0;
    width: 100%;
    padding: 0;
    z-index: 2;
    .desktop-limiter {
      flex-basis: 100%;
      text-align: center;
      padding-right: 70px;
      .header-promo-link {
        float: left;
        padding: 9px;
        margin-left: 20px;
        margin-top: 15px;
        span {
          display: none;
        }
      }
    }
    .mobile-fixed {
      background: ${color.brownRed};
    }
    .header-contacts-menu.open {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      background: ${color.brownRed};
      padding-top: 0;
      height: 75px;
    }
    .header-promo-link-mobile {
      display: block !important;
      margin: 0 ;
      padding: 9px 11px;
      position: absolute;
      left: 20px;
      top: 15px;
      background: ${color.transparentWhite1};
      border: none;
    }
    .line-id-mobile {
      display: block !important;
      margin: 0 ;
      padding: 9px 11px;
      position: absolute;
      right: 20px;
      top: 15px;
      background: ${color.transparentWhite1};
      border: none;
      box-shadow: none;
      svg {
        margin: 0 !important;
      }
    }
  }
  
  @media (max-width: ${breakpoints.xs}) {
    .desktop-limiter {
      position: relative;
      height: 70px;
      .header-promo-link {
        margin: 0 !important;
      }
    }

    @media (max-width: 767px) {
      .header-promo-link {
        margin: 0 ;
        padding: 9px;
        position: absolute;
        left: 20px;
        top: 15px;
      }  
    }
`

const BottomHeader = styled("div")`
  margin: 0;
  transition: all 0.35s ease-out;
  .bottom-nav {
    display: none;
  }

  @media (min-width: ${breakpoints.mdMin}) {
    &.fixed {
      margin: 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      background: ${color.brownRed};
      height: 57px;
      .top-nav {
        margin: 3px auto;
      }
      .submenu {
        top: 57px;
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    margin: 0;
    background: ${color.brownRed};
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    border-top: 1px solid ${color.darkRed};
    overflow: hidden;
    .bottom-nav {
      display: block;
      height: 48px;
      a,
      button {
        -webkit-appearance: none;
        border: 0;
        background: ${color.brownRed};
        width: 25%;
        display: block;
        float: left;
        text-align: center;
        padding: 9px 5px 0;
        &:active,
        &:focus {
          outline: none;
          svg {
            path {
              fill: ${color.white} !important;
            }
            filter: drop-shadow(1px 1px 6px ${color.peach});
          }
        }
      }
      a,
      button:last-of-type {
        position: relative;
      }
      a::before,
      button:last-of-type::before {
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        height: 35px;
        width: 1px;
        background: ${color.transparentWhite1};
      }
    }
  }
`

const NavUlWrap = styled("div")`
  ${NavUlStyles}
`

const Nav = styled("ul")`
  ${NavStylesNew}
  .mobile-collapse-link {
    outline: none;
  }
`

const SubMenuWrap = styled("div")`
  ${SubmenuStylesNew}
`

const handleMenuClick = e => {
  let menuItem = getClosest(e.target, "li")
  let hasSubMenu = menuItem.getElementsByClassName("submenu")
  if (
    !menuItem.classList.contains("open-item") &&
    !menuItem.classList.contains("home") &&
    hasSubMenu.length
  ) {
    e.preventDefault()
    closeMenuItems()
    const activeLink = document.querySelector(".active")
    if (activeLink) {
      activeLink.classList.remove("active")
    }
    menuItem.classList.add("open-item")
  } else {
    if (menuItem.classList.contains("no-link")) {
      e.preventDefault()
    }
    closeMenuItems()
  }
}

const handleMouseEnter = e => {
  let menuItem = getClosest(e.target, "li")
  let hasSubMenu = menuItem.getElementsByClassName("submenu")
  //const appHeader = document.getElementById('app-header');
  if (
    !isMobile &&
    !menuItem.classList.contains("open-item") &&
    !menuItem.classList.contains("home") &&
    hasSubMenu.length
  ) {
    closeMenuItems()
    menuItem.classList.add("open-item")
  }
}

const handleMouseLeave = () => {
  closeMenuItems()
}

const unBlockLayout = () => {
  const layout = document.getElementById("all-content")
  layout.classList.remove("inter-blocked")
}

const closeMobileMenu = () => {
  let elems = document.querySelectorAll(".open")
  ;[].forEach.call(elems, function (el) {
    el.classList.remove("open")
  })
  const navWrap = document.getElementById("nav-wrap")
  navWrap.classList.remove("search-open")
  unBlockLayout()
}

const mobileBackHandler = () => {
  closeMenuItems()
}

const closeMenuItems = () => {
  let elems = document.querySelectorAll("li.open-item")
  ;[].forEach.call(elems, function (el) {
    el.classList.remove("open-item")
  })
}

const getClosest = (elem, selector) => {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        let matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1
      }
  }

  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem
  }
  return null
}

const closePopupHandler = () => {
  const layout = document.getElementById("all-content")
  if (layout) {
    layout.classList.remove("popup-blocked")
  }

  let activePopup = document.querySelector(".popup-open.popup-window")
  if (activePopup) {
    activePopup.classList.remove("popup-open")
  }
}

const handleScroll = () => {
  const elToFix = document.getElementById("toFix")
  const elToFixMobile = document.getElementById("toFixMobile")
  if (window.pageYOffset > elToFix.offsetTop) {
    elToFix.classList.add("fixed")
  } else {
    elToFix.classList.remove("fixed")
  }
  if (window.pageYOffset > elToFixMobile.offsetTop) {
    elToFixMobile.classList.add("mobile-fixed")
  } else {
    elToFixMobile.classList.remove("mobile-fixed")
  }
}

// const invokePopup = (event, popupId) => {
//   event.preventDefault();
//   //const iframeSrc = src;
//   const layout = document.getElementById('all-content');
//   layout.classList.add('popup-blocked');
//   const popupWindow = document.getElementById(popupId);
//   popupWindow.classList.add('popup-open');
// };

const MainMenu = () => {
  useEffect(() => {
    if (window.location.pathname.indexOf("game", 0) > -1)
      document.getElementById("nav-wrap").classList.add("invisible-header")
    window.addEventListener("scroll", handleScroll)
    window.addEventListener("click", function (event) {
      if (event.target.classList.contains("accordion-title")) {
        let parentEl = event.target.parentNode
        if (parentEl.classList.contains("accordion-open")) {
          parentEl.classList.remove("accordion-open")
        } else {
          parentEl.classList.add("accordion-open")
        }
      }
    })
    /* Same for all popup windows */
    window.addEventListener("mouseup", function (event) {
      let activePopup = document.querySelector(".popup-open")
      if (
        event.target !== activePopup &&
        !getClosest(event.target, ".popup-open")
      ) {
        closePopupHandler()
      }
    })
    window.addEventListener("touchend", function (event) {
      let item = document.getElementById("nav")
      let activeMenuItem = document.querySelectorAll(".open-item")
      if (
        event.target !== item &&
        activeMenuItem.length &&
        !getClosest(event.target, "#nav")
      ) {
        closeMenuItems()
      }
    })
    /* Same for all popup windows */
    window.addEventListener("touchend", function (event) {
      let activePopup = document.querySelector(".popup-open")
      if (
        event.target !== activePopup &&
        !getClosest(event.target, ".popup-open")
      ) {
        closePopupHandler()
      }
    })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })
  const value = useContext(StoreContext)
  const username = value.username
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpApiMenusMenusItems(
            filter: { slug: { eq: "main-nav" } }
          ) {
            nodes {
              id
              name
              slug
              items {
                title
                classes
                attr
                object_slug
                path
                target
                xfn
                url
                wordpress_children {
                  attr
                  classes
                  description
                  path
                  title
                  xfn
                  object_slug
                  wordpress_id
                  target
                  url
                }
              }
            }
          }
        }
      `}
      render={data => (
        <NavWrap className="nav-wrap" id="nav-wrap">
          <DesktopWrapper>
            <div className="desktop-limiter" id="toFixMobile">
              <a
                href="https://www.gclub-casino.com/promotion/"
                target="_blank"
                rel="noreferrer noopener"
                id="header-promo-link-id"
                className="header-promo-link button transparent-button"
                title="Our promotions"
              >
                <Gift />
                <span>Promotion</span>
              </a>
              <Logo to="/" text={`${process.env.LOGO_ALT}`} />
            </div>
            <HeaderContacts>
              <div className="service-wrapper header-search-wrapper">
                <MobileCloseLink handlerFunction={closeMobileMenu} />
                <SearchForm handlerFunction={closeMobileMenu} />
              </div>
            </HeaderContacts>

            <TopSearch />
          </DesktopWrapper>
          <BottomHeader id="toFix">
            <div className="bottom-nav">
              <Hamburger />
              <MobileLineLink />
              <MobileCallLink />
              <MobileContactLinkFresh />
            </div>

            <NavUlWrap id="nav" className="top-nav">
              <Nav className="top-nav-ul">
                {data.allWordpressWpApiMenusMenusItems.nodes[0].items.map(
                  item => (
                    <li
                      className={
                        item.object_slug === "home-page"
                          ? "menu-item home " + item.classes
                          : "menu-item " + item.classes
                      }
                      key={item.object_slug + Math.random()}
                    >
                      {item.target && (
                        <a
                          className="top-menu-link"
                          href={item.url}
                          target={
                            !item.url.includes("#") ? item.target : "_self"
                          }
                        >
                          {item.attr && (
                            <IcomoonIcon
                              icon={item.attr}
                              color={color.red}
                              size={20}
                            />
                          )}
                          <span>{item.title}</span>
                          {item.xfn && (
                            <span className="item-label">{item.xfn}</span>
                          )}
                        </a>
                      )}
                      {((!item.target && item.path) ||
                        item.object_slug === "home-page") && (
                        <Link
                          activeClassName="active"
                          className="top-menu-link"
                          to={"/" + item.path.replace("category/", "")}
                          onMouseEnter={e => handleMouseEnter(e)}
                          onClick={e => handleMenuClick(e)}
                        >
                          {item.attr && (
                            <IcomoonIcon
                              icon={item.attr}
                              color={color.red}
                              size={20}
                            />
                          )}
                          <span>
                            {item.title}
                            {item.xfn && (
                              <span className="item-label">{item.xfn}</span>
                            )}
                          </span>
                        </Link>
                      )}
                      {!item.target &&
                        !item.path &&
                        item.object_slug !== "home-page" && (
                          <a
                            className="top-menu-link"
                            href="/"
                            onClick={e => handleMenuClick(e)}
                            onMouseEnter={e => handleMouseEnter(e)}
                          >
                            {item.attr && (
                              <IcomoonIcon
                                icon={item.attr}
                                color={color.red}
                                size={20}
                              />
                            )}
                            <span>
                              {item.title}
                              {item.xfn && (
                                <span className="item-label">{item.xfn}</span>
                              )}
                            </span>
                          </a>
                        )}
                      {item.wordpress_children && (
                        <span
                          className="mobile-collapse-link"
                          role="button"
                          tabIndex={0}
                          aria-roledescription="Toggle Submenu"
                          onClick={mobileBackHandler}
                          onKeyDown={mobileBackHandler}
                        >
                          <IcomoonIcon
                            icon="arrow-down-simple"
                            color={color.transparentWhite2}
                            size={16}
                          />
                        </span>
                      )}

                      {item.wordpress_children && (
                        <SubMenuWrap
                          className="submenu"
                          onMouseLeave={handleMouseLeave}
                        >
                          <ul>
                            {item.wordpress_children.map(subitem => (
                              <li
                                key={subitem.wordpress_id + Math.random()}
                                className={"submenu-item " + subitem.classes}
                              >
                                {subitem.target ? (
                                  <a
                                    href={subitem.url}
                                    target={
                                      !subitem.url.includes("#")
                                        ? subitem.target
                                        : "_self"
                                    }
                                  >
                                    {!subitem.attr && (
                                      <div className="subitem-arrow">
                                        {item.attr === "bakkarat" &&
                                        subitem.classes ? (
                                          <IcomoonIcon
                                            icon="play"
                                            color={color.white}
                                            size={16}
                                          />
                                        ) : (
                                          <IcomoonIcon
                                            icon="arrow-right"
                                            color={color.white}
                                            size={16}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <span className="subitem-title">
                                      {subitem.title}
                                      {subitem.xfn && (
                                        <span className="subitem-label">
                                          {subitem.xfn}
                                        </span>
                                      )}
                                    </span>
                                    {subitem.description && (
                                      <span className="subitem-description">
                                        {subitem.description}
                                      </span>
                                    )}
                                  </a>
                                ) : (
                                  <Link
                                    activeClassName="active-sub"
                                    to={
                                      "/" +
                                      subitem.path.replace("category/", "")
                                    }
                                  >
                                    {!subitem.attr && (
                                      <div className="subitem-arrow">
                                        {item.attr === "bakkarat" &&
                                        subitem.classes ? (
                                          <IcomoonIcon
                                            icon="play"
                                            color={color.white}
                                            size={16}
                                          />
                                        ) : (
                                          <IcomoonIcon
                                            icon="arrow-right"
                                            color={color.white}
                                            size={16}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <span className="subitem-title">
                                      {subitem.title}
                                      {subitem.xfn && (
                                        <span className="subitem-label">
                                          {subitem.xfn}
                                        </span>
                                      )}
                                    </span>
                                    {subitem.description && (
                                      <span className="subitem-description">
                                        {subitem.description}
                                      </span>
                                    )}
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        </SubMenuWrap>
                      )}
                    </li>
                  )
                )}
                <li className="mobile-only">
                  <a
                    href="https://www.gclub-casino.com/promotion/"
                    target="_blank"
                    rel="noreferrer noopener"
                    id="header-promo-link-id"
                    className="header-promo-link button transparent-button"
                    title="Our promotions"
                  >
                    <Gift />
                    <span>Promotion</span>
                  </a>
                  {username ? (
                    <LogoutButton />
                  ) : (
                    <a
                      className="button transparent-button register-button"
                      href={'https://www.gclub-casino.com/register/'}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <span>เข้าสู่ระบบ</span>
                    </a>
                  )}
                </li>
              </Nav>
            </NavUlWrap>
          </BottomHeader>
        </NavWrap>
      )}
    />
  )
}

export default MainMenu
